import { APP_ROUTES } from '@lib/route/constants';
import { Tenant } from '@lib/tenants/types';

export const FAIRPLAY_MOBILE_CONFIG: Tenant = {
  config: {
    setup: {
      id: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: 'FAIRPLAY_MOBILE',
        staging: undefined,
      },
      name: 'fairplay-mobile',
      cmsSubdomain: {
        local: undefined,
        demo: undefined,
        staging: undefined,
        qa: undefined,
        prod: 'fairplay-mobile-prod',
      },
      ryftPublicKey: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: 'pk_HVBLifv/O2HE4O8N92nY5TjDiOJ6UcOOLypkT9M/uVdF8DuqC3Ykdagfvpldc2Rc',
        staging: undefined,
      },
      paypalPublicKey: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: undefined,
        staging: undefined,
      },
      authTenantId: {
        local: undefined,
        demo: undefined,
        staging: undefined,
        qa: undefined,
        prod: '8b6mpzzn',
      },
      ocbDigitalBaseUrl: {
        demo: undefined,
        qa: undefined,
        prod: 'https://fairplay-mobile.com/digital/api',
        staging: undefined,
      },
      blueBoxChatBotUrl: {
        demo: undefined,
        qa: undefined,
        staging: undefined,
        prod: 'https://chat-wg.m-bl.pl/static/widget/index.html',
      },
      googleTagManagerId: {
        demo: undefined,
        qa: undefined,
        staging: undefined,
        prod: 'GTM-MP4TFG5T',
      },
    },
  },
  cmsRoutes: [
    {
      type: 'page',
      path: APP_ROUTES.ROOT_UID.path,
    },
    {
      type: 'home-page',
      path: APP_ROUTES.HOME.path,
    },
    {
      type: 'privacy-policy',
      path: APP_ROUTES.PRIVACY_POLICY.path,
    },
    {
      type: 'available_products',
      path: APP_ROUTES.PLANS.path,
    },
    {
      type: 'terms-and-conditions',
      path: APP_ROUTES.TERMS_AND_CONDITIONS.path,
    },
  ],
  theme: {
    mainFontFamily: {
      name: 'Cy',
      fontFiles: [
        {
          path: '/tenants/fairplay-mobile/fonts/Cy-Regular.woff2',
          weight: 400,
          format: 'woff2',
        },
        {
          path: '/tenants/fairplay-mobile/fonts/Cy-Bold.woff2',
          weight: 700,
          format: 'woff2',
        },
        {
          path: '/tenants/fairplay-mobile/fonts/Cy-ExtraBold.woff2',
          weight: 800,
          format: 'woff2',
        },
        {
          path: '/tenants/fairplay-mobile/fonts/Cy-Black.woff2',
          weight: 900,
          format: 'woff2',
        },
      ],
    },
    tenantStyles: {
      '*': {
        fontFeatureSettings: '"calt" 0, "tnum"',
      },
    },
  },
  seo: {
    displayName: 'Footballerista Mobile',
  },
};
