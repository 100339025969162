import { AvailableLocales } from '@lib/route/types';
import { enGB, es, tr, ar, it, fr, zhCN } from 'date-fns/locale';

export const DEFAULT_DATE_FNS_LOCALE = enGB;

export const NEXTJS_LOCALE_TO_DATE_FNS: Record<AvailableLocales, Locale> = {
  'en-gb': enGB,
  'es-es': es,
  tr: tr,
  'it-it': it,
  'fr-fr': fr,
  'ar-ly': ar,
  'ar-ae': ar,
  'zh-cn': zhCN,
};
